
import EstimateService from "@/services/estimate/estimate.service";
import core from "@/core";
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";

export default mixins(MixinsPage).extend({
  name: "Home",
  data: () => ({
    ready: false,
    visible: {
      estimate: true,
      specialPrice: false,
      eventRoulette: false,
      cleaningEstimate: false,
      elasticcoatEstimate: false,
      nanocoatingEstimate: false,
    },
    estimate: null as any,
    estimatePage: "/estimate",
    cleaningEstimatePage: "/estimate?type=c",
    elasticcoatEstimatePage: "/estimate?type=e",
    nanocoatingEstimatePage: "/estimate?type=n",
  }),
  mounted() {
    core.loader.show();
    this.$nextTick(async () => {
      try {
        const estimate = (await EstimateService.get()) as any;
        if (estimate.customerViewYn === "N") {
          this.visible.estimate = false;
        }
        if (estimate.lastSpecialPriceSmsPush != null) {
          this.visible.specialPrice = true;
        }
        if (estimate.eventWinnerList != null) {
          this.visible.eventRoulette = estimate.eventWinnerList.length > 0;
        } else {
          this.visible.eventRoulette = false;
        }
        // console.log("estimate : ", estimate);
        if (estimate.categoryText.indexOf("청소") && estimate.cleaningSitePaymentYn === "Y") {
          // console.log("견적서 분리");
          this.visible.cleaningEstimate = true;
          this.estimatePage = "/estimate?type=ic";
        }
        if (estimate.categoryText.indexOf("탄성코트") && estimate.elasticcoatPaymentYn === "Y") {
          // console.log("견적서 분리");
          this.visible.elasticcoatEstimate = true;
          this.estimatePage = "/estimate?type=ic";
        }
        if (estimate.categoryText.indexOf("나노코팅") && estimate.nanocoatingPaymentYn === "Y") {
          // console.log("견적서 분리");
          this.visible.nanocoatingEstimate = true;
          this.estimatePage = "/estimate?type=ic";
        }

        this.estimate = estimate;
      } catch (e) {
        console.log(e);
      } finally {
        this.ready = true;
        core.loader.hide();
      }
    });
  },
  methods: {
    getPhoneHtml() {
      if (this.estimate != null && this.estimate.company != null) {
        const phone = core.utils.format.hyphenPhone(this.estimate.company.phone);
        return `<a href="tel:${phone}">${phone}</a>`;
      }
      return "";
    },
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        this.$router.push("/logout");
      }
    },
  },
});
