
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import StatusService from "@/services/status/status.service";
import core from "@/core";
import store from "@/store";
import { RouteMeta } from "@/router";

export default mixins(MixinsPageForm).extend({
  name: "StatusView",

  data: () => ({
    $viewer: null as any,
    request: {
      statusId: null as number | null,
    },
    status: null as any,
    iconSubMenu: {
      download: null as any,
    },
    app: store.state.app,
    viewerOptions: {
      inline: false,
      button: true,
      navbar: true,
      title: false,
      toolbar: true,
      tooltip: true,
      movable: true,
      zoomable: true,
      rotatable: false,
      scalable: false,
      transition: true,
      fullscreen: true,
      keyboard: false,
    },
  }),
  created() {
    const params = this.$route.params as any;
    this.request.statusId = params.id;
  },
  mounted() {
    const subMenu = (this.$route.meta as RouteMeta).subMenu;
    if (subMenu != null) {
      ["icon", "text"].forEach((key) => {
        if (subMenu[key] != null) {
          if (subMenu[key].list != null) {
            subMenu[key].list.forEach((menu) => {
              if (key === "icon") {
                this.iconSubMenu[menu.id] = menu;
              }
            });
          }
        }
      });
    }

    this.$nextTick(async () => {
      await this.getStatus();
    });
  },

  watch: {
    async "iconSubMenu.download.event"(event) {
      this.iconSubMenu.download.event = false;
      if (event) {
        try {
          const response = (await StatusService.getStatusImageFile(this.status.id)) as any;
          //console.log("response : ", response);
          core.loader.show("다운로드중...");
          await core.http.downloadFile(response.downloadUri + "?v=" + new Date().getTime());
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
      }
    },
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer;
    },
    imageHeight(item) {
      if (item.imageWidth && item.imageHeight) {
        const size = this.app.size;
        const cal = (size.width - 10) / item.imageWidth;
        return item.imageHeight * cal;
      }
    },
    onView(detail) {
      const imageUri = detail.imageUri;
      let selected = null as any;
      this.$viewer.images.some((el) => {
        if (el.src.indexOf(imageUri) > -1) {
          selected = el;
        }
      });
      if (selected != null) {
        selected.click();
      } else {
        this.$viewer.show();
      }
      // window.open(detail.imageUri, "_blank");
    },
    showEditPage() {
      this.$router.push({
        path: "/status/edit",
        query: {
          statusId: String(this.request.statusId),
        },
      });
    },
    async getStatus() {
      try {
        core.loader.show();
        this.status = await StatusService.getStatus(this.request.statusId as number);
        await this.$store.dispatch("topToolbar/changeTitle", this.status.title);
        // console.log("status : ", this.status);
        const schedule = this.status.schedule;
        if (schedule.category && schedule.category.name === "줄눈" && this.status.type === "A") {
          const customItems = [
            {
              imageUri: "/img/etc/grout_notice2.jpg?v=20240305",
              imageWidth: 1000,
              imageHeight: 1849,
            },
            { imageUri: "/img/etc/grout_notice.png", imageWidth: 2835, imageHeight: 3870 },
          ];
          customItems.forEach((customItem) => {
            const detail = {
              remark: "",
              fileDetailId: 0,
              imageThumbUri: customItem.imageUri,
              imageUri: customItem.imageUri,
              imageWidth: customItem.imageWidth,
              imageHeight: customItem.imageHeight,
            };
            this.status.detailList.unshift(detail);
          });
        }
      } catch (e) {
        console.log(e);
        await this.notFound();
      }
      core.loader.hide();
    },
  },
});
